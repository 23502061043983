import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import {
  HIRE_FIXED,
  HIRE_FIXED_CREDIT,
  PLANNING_TO_HIRE,
  PLANNING_TO_HIRE_CREDIT,
  R_NOT_SURE,
  R_NOT_SURE_CREDIT,
  USER_TYPE_VENDOR,
  propTypes,
} from '../../util/types';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import {
  Button,
  H2,
  H3,
  H5,
  IconCard,
  IconSpinner,
  LeadCard,
  Modal,
  ModalInMobile,
  NamedLink,
  Page,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { customerListingView, setActiveListing, vendorSpentCredits } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';
import { sendInquiry } from '../ListingPage/ListingPage.duck';
import logo from '../../assets/image/logo.png'
import { updateProfile, updateProfileUser } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: false,
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      isContactModalOpen: false,
      barHeight: typeof window !== 'undefined' && window.innerHeight - 100 + 'px',
      isModalOpen: false
    };
    this.handleResize = this.handleResize.bind(this);

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      barHeight: typeof window !== 'undefined' && window.innerHeight - 100 + 'px' // Recalculate height on window resize
    });
  }
  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};
      const dropNonFilterParams = false;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig, dropNonFilterParams),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig
    );

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      config,
      onSendInquiry,
      getListing,
      sendInquiryInProgress,
      sendInquiryError,
      fetchUserListings,
      currentUser,
      history,
      vendorCredits,
      isAuthenticated,
      onUpdateProfile,
      onVendorSpentCredits,
      vendorSpentCredits,
      fetchUserListingsInProgress,
      transactions,
      onCustomerListingView,
      listingApproved
    } = this.props;
    
    const isListingApproved = listingApproved && listingApproved.map((st) => st.attributes.state == "published").toString();
    
    const isVendorListing = currentUser?.attributes?.profile?.publicData?.userListingId;
    const isVendor =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.userRole == USER_TYPE_VENDOR;

    const handleNotInterested = values => {
      const id = values;

      const notInterested =
        (currentUser &&
          currentUser.attributes &&
          currentUser.attributes.profile &&
          currentUser.attributes.profile.publicData &&
          currentUser.attributes.profile.publicData.notInterested) ||
        [];

      if (currentUser && id) {
        notInterested.push({ listingId: id });
        const addedNotInterested = [...notInterested];
        const profile = {
          publicData: {
            notInterested: addedNotInterested,
          },
        };
        return onUpdateProfile(profile);
      }

    };

    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      defaultFiltersConfig,
      false
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    const availablePrimaryFilters = [...customPrimaryFilters, ...defaultFilters];
    const availableFilters = [
      ...customPrimaryFilters,
      ...defaultFilters,
      ...customSecondaryFilters,
    ];

    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, customSecondaryFilters, [])
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
        isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
        toggleSecondaryFiltersOpen: isOpen => {
          this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
        },
        selectedSecondaryFiltersCount,
      }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = listings ? listings.length : 0 ;
      // searchParamsAreInSync && hasPaginationInfo
      //   ? pagination.totalItems
      //   : pagination?.paginationUnsupported
      //     ? listings.length
      //     : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { bounds, origin, userSearchId = false } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    const isValidCredits = vendorCredits;

    const notInterstedUserId = currentUser?.attributes?.profile?.publicData?.notInterested?.map(
      st => st.listingId
    );
    const fetchedUser = fetchUserListings;
    const filteredUser =
      fetchedUser && fetchedUser.length && notInterstedUserId && notInterstedUserId.length
        ? fetchedUser.filter(st =>
          notInterstedUserId.findIndex(item => item == st.id.uuid) >= 0 ? false : st
        )
        : fetchedUser && fetchedUser.length
          ? fetchedUser
          : [];

    const category = currentUser?.attributes?.profile?.publicData?.category;
    const userArray = filteredUser && filteredUser.length ? filteredUser : [];

    const countryVendor = currentUser?.attributes?.profile?.publicData?.country;
    const locationVendor = currentUser?.attributes?.profile?.publicData?.location?.search;
    const matchedResponse = userArray?.filter(item => {
      const userCountry = item?.attributes?.publicData?.location?.search;
      const parts = locationVendor?.split(' ') ?? '';
      const country = parts[parts?.length - 1] ?? '';
      return countryVendor
        ? userCountry?.toLowerCase()?.includes(countryVendor?.toLowerCase())
        : userCountry?.toLowerCase()?.includes(country?.toLowerCase());
    });
    const isMobile = isWindowDefined && window.innerWidth < 800;
    const isCardView = userSearchId && isMobile;

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        className={css.page}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        {
          isVendor ? (
            <div className={css.vendorContainer}>
              <div className={css.showCards}>
                {matchedResponse && matchedResponse.length !== 0 && <div className={matchedResponse && matchedResponse.length ? css.showLeftCard : css.unshowRight}>
                  {isCardView ? null
                    : matchedResponse && matchedResponse.length ?
                      <div className={css.heading}>
                        <ul className={css.headingList}>
                          <li className={css.matchingFound}>{matchedResponse.length + ' Matching Leads'}</li>
                          <li className={css.servicesSubHeading}>{matchedResponse.length + ' Services'}</li>
                        </ul>
                      </div> : null
                  }
                  {isCardView ? null : <div className={css.matchingListWrapper}>
                    <div className={css.matchingList} style={{ height: this.state.barHeight }}>
                      {matchedResponse.map(user => {
                        const { title, publicData } = user.attributes;
                        const {
                          serviceType = [],
                          location,
                          eventTypePlanning,
                          specificCulture,
                          eventHiringDecision,
                          date,
                        } = publicData;

                        const eventDate = date ? date : "I'm not sure yet";
                        const creditDebit =
                          eventHiringDecision && eventHiringDecision == R_NOT_SURE
                            ? R_NOT_SURE_CREDIT
                            : eventHiringDecision && eventHiringDecision == PLANNING_TO_HIRE
                              ? PLANNING_TO_HIRE_CREDIT
                              : eventHiringDecision && eventHiringDecision == HIRE_FIXED
                                ? HIRE_FIXED_CREDIT
                                : 10;

                        const categoryName = serviceType
                          .filter(item => category.includes(item.label))
                          .map(filteredItem => filteredItem.label);

                        return (
                          <div
                            onClick={e => {
                              e.preventDefault();
                              history.push(
                                createResourceLocatorString(
                                  'SearchPage',
                                  routeConfiguration,
                                  {},
                                  { userSearchId: user.id.uuid }
                                )
                              );
                            }}
                            className={
                              !userSearchId || (userSearchId && userSearchId == user.id.uuid)
                                ? css.showLeftEachCardSelected
                                : css.showLeftEachCard
                            }
                          >
                            <div className={css.categoryRow}>
                              <div className={css.titleName}><b>{title}</b></div>
                              <div className={css.eventDate}>{eventDate}</div>
                            </div>
                            <div className={css.categoryName}>{categoryName.join(',')}</div>
                            <div className={css.categoryName}>{location.address ? location?.address : location?.search}</div>

                            <div className={css.additionalDetail}>
                              <div className={css.detailAdd}>Additional Details</div>
                              <div className={css.planName}>
                                <span>{eventTypePlanning},</span>
                                <span>{specificCulture}</span>
                              </div>
                            </div>
                            <div className={css.credits}>
                              <span className={css.creditNum}>{creditDebit} credits</span>{' '}
                              <span className={css.respondText}>Be the first to respond</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>}
                </div>}
                <div className={matchedResponse?.length != 0 ? css.showRightCard : css.noResponse}>
                  {fetchUserListingsInProgress || searchInProgress ?
                    <div className={css.cardSpinner}>
                      <IconSpinner type={true} />
                    </div> :
                    <>
                      {matchedResponse?.length != 0 ?
                        matchedResponse
                          .filter((user, index) => userSearchId ? user.id.uuid == userSearchId : isMobile ? false : index === 0)
                          .map(user => (
                            <LeadCard
                              user={user}
                              category={category}
                              isValidCredits={isValidCredits}
                              isModalOpen={val => this.setState({ isContactModalOpen: val })}
                              isAuthenticated={isAuthenticated}
                              onSendInquiry={onSendInquiry}
                              getListing={getListing}
                              sendInquiryInProgress={sendInquiryInProgress}
                              sendInquiryError={sendInquiryError}
                              userSearchId={userSearchId}
                              onVendorSpentCredits={onVendorSpentCredits}
                              currentUser={currentUser}
                              handleNotInterested={handleNotInterested}
                              matchedResponse={matchedResponse}
                              vendorSpentCredits={vendorSpentCredits}
                              transactions={transactions}
                              onCustomerListingView={onCustomerListingView}
                              isMobile={isMobile}
                            />
                          )) : (
                          <h3> You haven't found any match</h3>
                        )}
                    </>
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className={css.container}>
              <div className={css.searchResultContainer}>
                <SearchFiltersMobile
                  className={css.searchFiltersMobileMap}
                  urlQueryParams={validQueryParams}
                  // sortByComponent={sortBy('mobile')}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  showAsModalMaxWidth={MODAL_BREAKPOINT}
                  onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onOpenModal={this.onOpenMobileModal}
                  onCloseModal={this.onCloseMobileModal}
                  resetAll={this.resetAll}
                  selectedFiltersCount={selectedFiltersCountForMobile}
                  noResultsInfo={noResultsInfo}
                  isMapVariant
                >
                  {availableFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersMobile.${config.scope || 'built-in'}.${config.key}`}
                        idPrefix="SearchFiltersMobile"
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        liveEdit
                        showAsPopup={false}
                      />
                    );
                  })}
                </SearchFiltersMobile>
                <MainPanelHeader
                  className={css.mainPanelMapVariant}
                  // sortByComponent={sortBy('desktop')}
                  isSortByActive={sortConfig.active}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  noResultsInfo={noResultsInfo}
                >
                  <SearchFiltersPrimary {...propsForSecondaryFiltersToggle}>
                    {availablePrimaryFilters.map(config => {
                      return (
                        <FilterComponent
                          key={`SearchFiltersPrimary.${config.scope || 'built-in'}.${config.key}`}
                          idPrefix="SearchFiltersPrimary"
                          config={config}
                          marketplaceCurrency={marketplaceCurrency}
                          urlQueryParams={validQueryParams}
                          initialValues={initialValues(this.props, this.state.currentQueryParams)}
                          getHandleChangedValueFn={this.getHandleChangedValueFn}
                          intl={intl}
                          showAsPopup
                          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        />
                      );
                    })}
                  </SearchFiltersPrimary>
                </MainPanelHeader>
                {isSecondaryFiltersOpen ? (
                  <div className={classNames(css.searchFiltersPanel)}>
                    <SearchFiltersSecondary
                      urlQueryParams={validQueryParams}
                      listingsAreLoaded={listingsAreLoaded}
                      applyFilters={this.applyFilters}
                      cancelFilters={this.cancelFilters}
                      resetAll={this.resetAll}
                      onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
                    >
                      {customSecondaryFilters.map(config => {
                        return (
                          <FilterComponent
                            key={`SearchFiltersSecondary.${config.scope || 'built-in'}.${config.key}`}
                            idPrefix="SearchFiltersSecondary"
                            config={config}
                            marketplaceCurrency={marketplaceCurrency}
                            urlQueryParams={validQueryParams}
                            initialValues={initialValues(this.props, this.state.currentQueryParams)}
                            getHandleChangedValueFn={this.getHandleChangedValueFn}
                            intl={intl}
                            showAsPopup={false}
                          />
                        );
                      })}
                    </SearchFiltersSecondary>
                  </div>
                ) : (
                  <div
                    className={classNames(css.listingsForMapVariant, {
                      [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                    })}
                  >
                    {searchListingsError ? (
                      <H3 className={css.error}>
                        <FormattedMessage id="SearchPage.searchError" />
                      </H3>
                    ) : null}
                    {!isValidDatesFilter ? (
                      <H5>
                        <FormattedMessage id="SearchPage.invalidDatesFilter" />
                      </H5>
                    ) : null}
                    {searchInProgress ? <IconSpinner type={true} />
                      : <SearchResultsPanel
                        className={css.searchListingsPanel}
                        listings={listings}
                        pagination={listingsAreLoaded ? pagination : null}
                        search={parse(location.search)}
                        setActiveListing={onActivateListing}
                        isMapVariant
                        onSendInquiry={onSendInquiry}
                        getListing={getListing}
                        sendInquiryInProgress={sendInquiryInProgress}
                        sendInquiryError={sendInquiryError}
                        transactions={transactions}
                        history={history}
                        currentUser={currentUser}
                        onCustomerListingView={onCustomerListingView}
                        customerId={location}
                      />}
                  </div>
                )}
              </div>
            </div>
          )}


        {currentUser?.attributes?.emailVerified && isVendor && isListingApproved=="false" && (
          <Modal
            id="SearchApprovedListing.filters"
            isOpen={!isListingApproved ||( isListingApproved && isListingApproved=="false")}
            onClose={()=>{}}
            onManageDisableScrolling={() => { }}
            className={css.modalCloseBtnHide}
          >
            <div className={css.modalContent}>
              <div>
                <img src={logo} alt="Logo" />
              </div>
              <p>Your account is currently under review. Please wait for approval.</p>
              <p>When your account is verified, you will receive an email.</p>
            </div>
          </Modal>
        )}
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    fetchUserListings,
    vendorCredits,
    vendorSpentCredits,
    fetchUserListingsInProgress,
    transactions,
    listingApproved
  } = state.SearchPage;

  const { isAuthenticated } = state.auth;
  const { sendInquiryInProgress, sendInquiryError } = state.ListingPage;
  const { currentUser } = state.user;

  const listings = getListingsById(state, currentPageResultIds);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    getListing,
    sendInquiryInProgress,
    sendInquiryError,
    fetchUserListings,
    currentUser,
    vendorCredits,
    isAuthenticated,
    vendorSpentCredits,
    fetchUserListingsInProgress,
    transactions,
    listingApproved
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onUpdateProfile: data => dispatch(updateProfileUser(data)),
  onVendorSpentCredits: (params, totalCredits) =>
    dispatch(vendorSpentCredits(params, totalCredits)),
  onCustomerListingView: (params) => dispatch(customerListingView(params))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
